<script setup lang="ts">
import { i18n } from '@/main'

import MainMenuItem from '@/components/generic/molecules/MainMenuItem.vue'

import AccountIcon from '@/assets/svg/nav-icons/account.svg?component'
import AdvisorIcon from '@/assets/svg/nav-icons/advisor.svg?component'
import ContractsIcon from '@/assets/svg/nav-icons/contracts.svg?component'
import HomeIcon from '@/assets/svg/nav-icons/home.svg?component'
import YagoLogo from '@/assets/svg/yago-logo.svg?component'

/** @ts-ignore */
const $t = i18n.global.t

const items = [
  { routeName: 'home', icon: HomeIcon },
  { routeName: 'contracts', icon: ContractsIcon, rootPath: $t('paths.contracts') },
  { routeName: 'advisor', icon: AdvisorIcon },
  { routeName: 'account', icon: AccountIcon, rootPath: $t('paths.account') }
]
</script>

<template>
  <nav class="z-10">
    <YagoLogo class="hidden text-white lg:mx-auto lg:mb-8 lg:mt-6 lg:block" />
    <ul class="m-auto grid max-w-lg grid-cols-4 lg:block">
      <MainMenuItem
        v-for="{ routeName, icon, rootPath } in items"
        :key="routeName"
        :route-name="routeName"
        :icon="icon"
        :root-path="rootPath ? rootPath : null" />
    </ul>
  </nav>
</template>
