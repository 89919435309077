import type { Me, MeToken } from '@/types/me'
import { Broker } from '@/types/me'
import { RequestStatus, handleRequest, initialRequestState } from '@/utils/restStore'
import type { RequestState } from '@/utils/restStore'
import { setSentryUser } from '@/utils/sentry'
import { defineStore } from 'pinia'

import Api from '@/services/api'

interface BookameetingParams {
  firstName: string
  lastName: string
  email: string
  phone: string
}
interface State {
  me: RequestState<Me>
  meToken: RequestState<MeToken>
  bookameetingParams: BookameetingParams
}

const initialState = (): State => ({
  me: initialRequestState(),
  meToken: initialRequestState(),
  bookameetingParams: {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }
})

export const useMeStore = defineStore({
  id: 'me',
  state: initialState,
  actions: {
    async fetchMe() {
      await handleRequest(Api.getMe, (me: RequestState<Me>) => {
        if (me.status === RequestStatus.RequestLoaded) {
          setSentryUser(me.data)
          this.bookameetingParams.firstName = me.data.firstName
          this.bookameetingParams.lastName = me.data.lastName
          this.bookameetingParams.email = me.data.email
          this.bookameetingParams.phone = me.data.mobilePhone ? me.data.mobilePhone.replace('+', '00') : ''
          localStorage.setItem('user_locale', me.data.locale)
        }
        this.me = me
      })
    },
    async fetchToken() {
      await handleRequest(Api.getMeToken, (token: RequestState<MeToken>) => {
        this.meToken = token
        if (token.status === RequestStatus.RequestLoaded) {
          localStorage.setItem('authToken', token.data.token)
        }
      })
    },
    async postOnboarded() {
      await handleRequest(Api.postOnboard, () => {})
    }
  },
  getters: {
    marshClient: (state: State): boolean => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data.fromBrokerId === Broker.Marsh : false
    },
    account: (state: State): Me | null => {
      return state.me.status === RequestStatus.RequestLoaded ? state.me.data : null
    },
    isUnfit(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && state.me.data.unfit
    },
    showOnboarding(state: State): boolean {
      return state.me.status === RequestStatus.RequestLoaded && !state.me.data.onboarded
    }
  }
})
