<script setup lang="ts">
import XMark from '@/assets/svg/ui/x-mark.svg?component'

const properties = defineProps<{
  open: boolean
  animationName?: string
  clickOutsideToClose?: boolean
  /**
   * The full mobile variant will take the full screen on mobile devices, like another page in the top of the stack.
   */
  variant?: 'fullOnMobile'
  closeButton?: boolean
  class?: string
  title?: string
  content?: string
  greenBackground?: boolean
}>()

const emit = defineEmits({
  close: () => true,
  clickOutside: () => true
})

const handleCloseModal = () => {
  emit('close')
}

const clickOutside = () => {
  emit('clickOutside')
  if (properties.clickOutsideToClose) handleCloseModal()
}
</script>

<template>
  <Teleport to="body">
    <div
      @click.self="clickOutside"
      class="fixed left-0 top-0 z-50 flex h-[calc(100dvh)] w-screen justify-center items-end xs:items-center xs:bg-opacity-50 overflow-y-scroll"
      v-show="properties.open"
      :class="[
        properties.variant === 'fullOnMobile' ? 'items-start' : 'items-end',
        properties.greenBackground ? 'bg-[rgba(2,80,75,.9)]' : 'bg-[rgba(0,0,0,0.9)]'
      ]">
      <Transition :name="properties.animationName ?? 'modal-slide'">
        <dialog
          :class="properties.class"
          class="w-full translate-y-0 rounded-t-2xl bg-white xs:rounded-2xl xs:max-w-sm text-center drop-shadow-[0px_20px_35px_rgba(2,80,75,0.3)]"
          v-if="properties.open"
          :open="properties.open">
          <!-- close button -->
          <button
            v-if="properties.closeButton"
            @click="handleCloseModal"
            class="absolute right-5 top-5 cursor-pointer rounded-full p-2 shadow">
            <XMark />
          </button>

          <div class="flex flex-col items-center px-4 py-5 text-emerald-950">
            <!-- content for generic style-->
            <template v-if="properties.title && properties.content">
              <h2 class="text-2xl font-semibold leading-tight">
                {{ properties.title }}
              </h2>
              <p class="text-sm leading-snug my-4">
                {{ properties.content }}
              </p>
            </template>

            <!-- content for custom style  -->
            <slot name="custom-content" v-else />

            <!-- buttons -->
            <slot name="buttons" />
          </div>
        </dialog>
      </Transition>
    </div>
  </Teleport>
</template>

<style>
.modal-slide-enter-active,
.modal-slide-leave-active {
  @apply transition-transform duration-300 ease-linear;
}

.modal-slide-enter-to,
.modal-slide-leave-from {
  @apply translate-y-0;
}

.modal-slide-leave-to,
.modal-slide-enter-from {
  @apply translate-y-full;
}
</style>
