import { DEFAULT_LOCALE } from '@/i18n/main'
import { useAuthStore } from '@/stores/auth'
import { createRouter, createWebHistory } from 'vue-router'

import Api from '@/services/api'

const createRouterWithI18n = (i18n: any) => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior() {
      // always scroll to top
      // note that this scroll behavior will have an effect on the #app but not the other components (see AppStructure for app container scroll logic)
      return { top: 0 }
    },
    routes: [
      //* authentication pages - START
      {
        path: '/:locale',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.login'),
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      {
        path: '/:locale' + i18n.global.t('paths.registration'),
        name: 'registration',
        component: () => import('../views/RegistrationView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      {
        path: '/:locale' + i18n.global.t('paths.password_registration') + '/:token',
        name: 'password_registration',
        component: () => import('../views/PasswordRegistrationView.vue'),
        meta: { public: true, requiresUnauthenticated: true }
      },
      //* authentication pages - END

      //* contracts related -- START
      {
        path: '/:locale' + i18n.global.t('paths.contracts'),
        name: 'contracts',
        component: () => import('../views/ContractsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.add_contract'),
        name: 'add_contract',
        component: () => import('../views/AddContractView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id',
        name: 'contract_details',
        component: () => import('../views/ContractDetailsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.documents'),
        name: 'contract_documents',
        component: () => import('../views/ContractDocumentsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.covers'),
        name: 'covers',
        component: () => import('../views/ContractCoversView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.invoices'),
        name: 'invoices',
        component: () => import('../views/ContractInvoiceView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.contracts_and_amendments'),
        name: 'contracts_and_amendments',
        component: () => import('../views/ContractsAmendmentsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.contracts') + '/:id' + i18n.global.t('paths.insurance_certificates'),
        name: 'insurance_certificates',
        component: () => import('../views/InsuranceCertificatesView.vue')
      },
      //* contracts related -- END

      //* account related -- START
      {
        path: '/:locale' + i18n.global.t('paths.account'),
        name: 'account',
        component: () => import('../views/AccountView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.account') + i18n.global.t('paths.informations'),
        name: 'account_informations',
        component: () => import('../views/InformationsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.account') + i18n.global.t('paths.my_documents'),
        name: 'account_documents',
        component: () => import('../views/DocumentsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.account') + i18n.global.t('paths.signatures'),
        name: 'account_signatures',
        component: () => import('../views/DocumentsToSign.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.my_claims'),
        name: 'account_claims',
        component: () => import('../views/ClaimsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.account') + i18n.global.t('paths.referral'),
        name: 'account_referral',
        component: () => import('../views/ReferralView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.account') + i18n.global.t('paths.legales'),
        name: 'legales',
        component: () => import('../views/LegalesView.vue')
      },
      //* account related -- END
      {
        path: '/:locale' + i18n.global.t('paths.my_certificates'),
        name: 'my_certificates',
        component: () => import('../views/CertificatesView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.online_signature'),
        name: 'online_signature',
        component: () => import('../views/OnlineSignatureView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.my_documents'),
        name: 'my_documents',
        component: () => import('../views/DocumentsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.claim_report'),
        name: 'claim_report',
        component: () => import('../views/ClaimReportView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.advisor'),
        name: 'advisor',
        component: () => import('../views/AdvisorView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.life_procedure') + '/:id',
        name: 'life_procedure',
        component: () => import('../views/LifeProcedureView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.notifications'),
        name: 'notifications',
        component: () => import('../views/NotificationsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.claim_details') + '/:id',
        name: 'claim_details',
        component: () => import('../views/ClaimDetailsView.vue')
      },
      {
        path: '/:locale' + i18n.global.t('paths.claim_declaration') + '/:id',
        name: 'claim_declaration',
        component: () => import('../views/ClaimDeclarationView.vue')
      },
      {
        path: '/:locale' + '/:pathMatch(.*)*',
        redirect: () => {
          //* redirect to home for other paths
          return 'home'
        }
      }
    ]
  })

  // Navigation guards
  router.beforeEach(async (to, _from, next) => {
    if (to.query?.operator_id) localStorage.setItem('operatorId', to.query.operator_id as string)

    const paramsLocale = localStorage.getItem('i18n-locale')

    const authStore = useAuthStore()

    // Get authentication status if unknown
    if (authStore.isUnknown) {
      const authenticated = await Api.authenticated()
      authStore.setAuthenticated(authenticated)
    }

    // Redirect to login for non public routes if not authenticated
    if (!to.meta.public && !authStore.isAuthenticated) {
      if (to.name) {
        // store nextRoute name before redirect to login
        localStorage.setItem('nextRouteName', to.name as string)
        // store nextRoute locale before redirect to login
        localStorage.setItem(
          'nextRouteLocale',
          to.params.locale ? (to.params.locale as string) : paramsLocale ? paramsLocale : DEFAULT_LOCALE
        )
      }
      // redirect to login
      return next({ name: 'login', params: { locale: paramsLocale } })
    }

    // Redirect to nextRoute after login and clear nextRoute values
    if (authStore.isAuthenticated && localStorage.getItem('nextRouteName')) {
      const nextName = localStorage.getItem('nextRouteName')
      const nextLocale = localStorage.getItem('nextRouteLocale')
      localStorage.removeItem('nextRouteName')
      localStorage.removeItem('nextRouteLocale')
      if (nextName)
        // redirect to nextRoute stored
        return next({ name: nextName, params: { locale: nextLocale } })
    }

    // Redirect to home with correct locale if no route name or path
    if (authStore.isAuthenticated && !to.name && to.path === '/') {
      return next({ name: 'home', params: { locale: paramsLocale } })
    }

    // Redirect to home for routes requiring unauthenticated
    if (to.meta.requiresUnauthenticated && authStore.isAuthenticated) {
      return next({ name: 'home', params: { locale: paramsLocale } })
    }

    return next()
  })

  return router
}

export default createRouterWithI18n
