import { app, router } from '@/main'
import type { Me } from '@/types/me'
import * as Sentry from '@sentry/vue'

/**
 * Initialize Sentry with app and router
 */
export const initializeSentry = () => {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    trackComponents: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  })
}

/**
 * Set the Sentry user to provide more information in Sentry
 * @param {Me} me - the information about the user
 */
export const setSentryUser = (me: Me) => {
  Sentry.setUser({
    id: me.uuid,
    email: me.email
  })
}

/**
 * Clear the Sentry user
 */
export const clearSentryUser = () => {
  Sentry.setUser(null)
}
