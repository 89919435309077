<script setup lang="ts">
import type { Component } from 'vue'
import { RouterLink } from 'vue-router'

const properties = defineProps<{
  routeName: string
  icon: Component
  rootPath: string | null
}>()

const titleKey = `general.main_menu.${properties.routeName}`
</script>

<template>
  <li class="text-center text-xs text-sage-500 md:text-sm lg:mx-8 lg:my-2 lg:text-white">
    <RouterLink
      :to="{ name: routeName, params: { locale: $i18n.locale } }"
      class="flex flex-col items-center justify-center lg:h-12 lg:flex-row lg:justify-start lg:rounded-xl lg:px-4 lg:align-middle"
      :class="{ 'router-link-active': rootPath && $route.path.includes(rootPath) }">
      <Suspense>
        <component :is="icon" class="lg:mr-4" />
      </Suspense>
      <span class="mt-2 lg:mt-0">{{ $t(titleKey) }}</span>
    </RouterLink>
  </li>
</template>

<style scoped>
.router-link-active {
  @apply text-blue-500;
}

@media screen and (min-width: 1024px) {
  .router-link-active {
    @apply bg-[#23615C] text-white;
  }
}
</style>
