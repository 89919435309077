import { ClaimStatuses } from '@/types/claim'
import type { DateStr } from '@/types/general'
import type { ProductType } from '@/types/productTypes'

export interface Authenticated {
  authenticated: boolean
}

export interface MeToken {
  token: string
}

export interface Me {
  uuid: string
  firstName: string
  lastName: string
  email: string
  locale: string
  gender: string | null
  mobilePhone: string | null
  birthdate: DateStr | null
  createdAt: DateStr
  mobileDevice: boolean
  clientCardUrl: string
  pendingActions: PendingAction[] | PendingClaim[]
  otherPendingActionsCount: Number
  anyContract: boolean
  anyActiveMotorContract: boolean
  address: {
    street: string
    streetNumber: string
    zipCode: string
    city: string
    country: string
  } | null
  unfit: boolean
  onboarded: boolean
  advisor: {
    firstName: string
    lastName: string
    profilePictureUrl: string
  }
  fromBrokerId: Broker | null
}

export enum Broker {
  Marsh = 'marsh'
}

export interface PendingAction {
  link: string
  uuid: string
  type: string
  productType: ProductType
  title: string
}

export interface PendingClaim {
  aliases: string[]
  claimId: string
  contractNumber: string
  contractType: ProductType
  references: string[]
  settlementAmount: number | null
  status: ClaimStatuses
  type: PendingType.Claim
  statusDate: string
  licencePlate: string | null
}

export enum PendingType {
  Claim = 'claim',
  ContractRequest = 'contract_request',
  Document = 'document',
  Signature = 'signing',
  Unpaid = 'unpaid',
  Offer = 'offer'
}
